import $ from "jquery";

$(function() {
    $(window).on("scroll", function() {
        if ($(this).scrollTop() > 100) {
            $(".js-fix-contact").fadeIn();
            } else {
            $(".js-fix-contact").fadeOut();
        }

        const scrollHeight = $(document).height();
        const scrollPosition = $(window).height() + $(window).scrollTop();
        const footHeight = $("footer").height();

        if ( scrollHeight - scrollPosition  <= footHeight ) {
            $(".js-fix-contact").css({
                "position":"absolute",
                "bottom": 20 + footHeight,
            });
        } else {
            $(".js-fix-contact").css({
                "position":"fixed",
                "bottom": "10px",
            });
        }
    });
});
