import $ from "jquery";

$(function() {
    if (location.pathname === '/') {
        const URL = '/php/rss.php'
        const PARSE_XML = function (xmlDocument) {
            const parser = new DOMParser()
            const dom = parser.parseFromString(xmlDocument, 'application/xml')
            const items = dom.documentElement.getElementsByTagName('item')
            const noImageUrl = '/images/common/no-image.png';

            const viewElem = document.getElementById('rss-view');

            let itemLen = items.length > 3 ? 3 : items.length;
            for (let i = 0; i < itemLen; i++) {
                let postThumbnails = items[i].getElementsByTagName('media:thumbnail');
                let postThumbnailUrl = postThumbnails.length === 0 ? null : postThumbnails[0].textContent;
                let postTitle = items[i].getElementsByTagName('title')[0].textContent;
                let postDescription = items[i].getElementsByTagName('description')[0].textContent;
                let postLinkUrl = items[i].getElementsByTagName('link')[0].textContent;

                let headerElem = document.createElement('h4');
                headerElem.textContent = postTitle;

                let descriptionElem = document.createElement('p');
                descriptionElem.textContent = postDescription.replace(/(<([^>]+)>)/gi, '').replace(/続きをみる/gi, '');

                let wrapElem = document.createElement('div');
                wrapElem.setAttribute('class', 'inner');
                wrapElem.appendChild(headerElem);
                wrapElem.appendChild(descriptionElem);

                let imageElem = document.createElement('img');
                imageElem.setAttribute('src', postThumbnailUrl === null ? noImageUrl : postThumbnailUrl);

                let anchorElem = document.createElement('a');
                anchorElem.setAttribute('href', postLinkUrl);
                anchorElem.setAttribute('target', '_blank');
                anchorElem.appendChild(imageElem);
                anchorElem.appendChild(wrapElem);

                let listElem = document.createElement('li');
                listElem.appendChild(anchorElem);

                viewElem.appendChild(listElem);
            }
        }
        fetch(URL).then(response => response.text()).then(xmlData => PARSE_XML(xmlData))
    }
});
