import $ from "jquery";

$(function() {
    // illust01
    $('#contents').css("opacity","0");
    setInterval(function(){
        $(".js-illust").addClass("popup");
    }, 500);

    // illust02
    var shake_target = $('.js-shake-target');
    var check_target = shake_target.length;

    function replaceAddClass(i) {
        shake_target.eq(i).siblings().removeClass('shaking');
        shake_target.eq(i).addClass('shaking');
    }

    var i = 0; replaceAddClass(i);
    setInterval(function(){
        i++;
        if ( !(i < check_target) ) { i = 0; }
        replaceAddClass(i);
    }, 3000);
});
