import $ from "jquery";

$(function() {
    $(".js-modal-open").on("click", function () {
        $(this).next('.js-modal').fadeIn();
        $(this).next('.js-modal').children('.js-modal-close-bg').fadeIn();
        $("body").addClass("scroll-stop");
        $(".p-home__modal-container").addClass("modal-open");
        return false;
    });
    $(".js-modal-close-bg").on("click", function () {
        $(this).fadeOut();
        $('.js-modal').fadeOut();
        $("body").removeClass("scroll-stop");
        setTimeout(function(){
            $(".p-home__modal-container").removeClass("modal-open");
        },500);
        return false;
    });
});
