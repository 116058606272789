import $ from "jquery";

$(function() {
    $(".js-arch-title").each(function() {
        var content = $(this).html();
        var trimText = $.trim(content);
        var newText = "";

        trimText.split("").forEach(function(e) {
            if(e == ' '){
                newText += '<span> </span>';
            } else {
                newText += '<span>' + e + '</span>';
            }
        });

        $(this).html(newText);
    });
});
